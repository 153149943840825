import React, { useState, useEffect } from "react";
import { send } from "@emailjs/browser";
import PropTypes from "prop-types";

const EmailQuoteModal = ({ isOpen, onClose, formJson }) => {
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Helper function to format money
  const formatMoney = (amount) => {
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(amount);
  };

  // Helper function to parse amount
  const parseAmount = (value) => {
    if (typeof value === "string") {
      return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
    }
    return parseFloat(value) || 0;
  };

  const handleSendEmail = async () => {
    if (!email) {
      setError("Please enter an email address.");
      return;
    }

    setIsSending(true);
    setError("");

    const netAmountFinancedV2 = parseAmount(formJson.netAmountFinanced);
    const originationFeeWithGSTV2 =
      parseAmount(formJson.brokerOriginationFee) * 1.1;
    const loanApplicationFeeWithGSTV2 =
      parseAmount(formJson.loanApplicationFee) * 1.1;
    const brokerageWithGSTV2 = parseAmount(formJson.brokerage);

    const templateParams = {
      purchasePrice:
        formatMoney(parseAmount(formJson.purchasePrice)) || formatMoney(0),
      cashDeposit: formatMoney(parseAmount(formJson.cashDeposit)),
      tradeIn: formatMoney(parseAmount(formJson.tradeIn)),
      payoutAmount: formatMoney(parseAmount(formJson.payoutAmount)),
      netAmountFinanced: formatMoney(
        parseAmount(formJson.netAmountFinanced) || netAmountFinancedV2,
      ),
      originationFeeWithGST: formatMoney(
        parseAmount(formJson.originationFeeWithGST) || originationFeeWithGSTV2,
      ),
      loanApplicationFeeWithGST: formatMoney(
        parseAmount(formJson.loanApplicationFeeWithGST) ||
          loanApplicationFeeWithGSTV2,
      ),
      totalLoanAmount: formatMoney(
        parseAmount(formJson.totalLoanAmount) ||
          parseAmount(formJson.totalLoanAmount),
      ),
      balloonPayment: formatMoney(
        parseAmount(formJson.balloonPayment) || parseAmount(formJson.repayment),
      ),
      brokerageWithGST: formatMoney(
        parseAmount(formJson.brokerageWithGST) || brokerageWithGSTV2,
      ),
      paymentCycle: formJson.paymentCycle || formJson.paymentFrequency,
      paymentTime: formJson.paymentTime || formJson.paymentTiming,
      repayment: formatMoney(
        parseAmount(formJson.repayment) ||
          Math.abs(parseAmount(formJson.repayment)),
      ),
      firstInstallment: formatMoney(
        parseAmount(formJson.firstInstallment) ||
          Math.abs(parseAmount(formJson.firstInstallment)),
      ),
      financeBrokerageFee: formJson.financeBrokerageFee,
      financeApplicationFee: formJson.financeApplicationFee,
      reply_to: email,
    };

    try {
      await send(
        "service_w0ntp3c",
        "template_803yfpl",
        templateParams,
        "rIAr1juthcLNT10cv",
      );
      onClose();
    } catch (error) {
      setError("Failed to send email. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  if (!isOpen) return null;

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "400px",
    width: "100%",
    maxHeight: "90vh",
    overflowY: "auto",
  };

  const buttonStyle = {
    backgroundColor: "#05dd80",
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "20px",
    width: "100%",
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  };

  return (
    <div style={modalStyle} onClick={onClose}>
      <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={{ marginBottom: "20px", fontSize: "24px" }}>Send Quote</h2>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter client's email address"
          style={inputStyle}
          required
        />
        {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={handleSendEmail}
            disabled={isSending}
            style={buttonStyle}
          >
            {isSending ? "Sending..." : "Send Quote"}
          </button>
          <button
            onClick={onClose}
            style={{
              ...buttonStyle,
              backgroundColor: "#999999",
              marginTop: "10px",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

EmailQuoteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formJson: PropTypes.object,
};

export default EmailQuoteModal;
