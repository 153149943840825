import React from "react";
import PropTypes from "prop-types";
import "../index.css";
import InputField from "./InputField";
import logo from "../images/logo.svg";

const AssetFinanceCalculator = ({
  onShowPayments,
  formData,
  onFormDataChange,
}) => {
  const inputFields = [
    {
      name: "purchasePrice",
      label: "Purchase Price",
      placeholder: "Enter Purchase Price",
      type: "number",
      format: "money",
    },
    {
      name: "cashDeposit",
      label: "Cash Deposit",
      placeholder: "Enter Cash Deposit",
      type: "number",
      format: "money",
    },
    {
      name: "tradeIn",
      label: "Trade In",
      placeholder: "Enter Trade in value",
      type: "number",
      format: "money",
    },
    {
      name: "payoutAmount",
      label: "Payout Amount",
      placeholder: "Enter Payout Amount",
      type: "number",
      format: "money",
    },
    {
      name: "interestRate",
      label: "Financier Rate",
      placeholder: "Enter Financier Rate",
      type: "number",
      format: "percentage",
    },
  ];

  const handleInputChange = (name, value) => {
    onFormDataChange({ [name]: value });
  };

  return (
    <div className="iphone">
      <div className="div">
        <div className="navbar">{/* Navbar content */}</div>

        <div className="text-wrapper-4">Asset Finance Calculator</div>
        <div className="frame">
          {inputFields.map((field, index) => (
            <InputField
              key={index}
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
              format={field.format}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
            />
          ))}
        </div>

        <div className="overlap" onClick={onShowPayments}>
          <div className="text-wrapper-2">Next →</div>
        </div>

        <div className="group">
          <div className="text-wrapper-5">Powered by</div>
          <img className="image" alt="Powered by logo" src={logo} />
        </div>
      </div>
    </div>
  );
};

AssetFinanceCalculator.propTypes = {
  onShowPayments: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
};

export default AssetFinanceCalculator;
