import React, { useEffect } from "react";
import "../index.css";
import PropTypes from "prop-types";
import InputField from "./InputField";
import backArrowIcon from "../images/back-arrow-icon.png";
import logo from "../images/logo.svg";
import { FieldCalculationType } from "../../shared/constants";

const Payments = ({ onBack, onShowFees, formData, onFormDataChange }) => {
  const purchasePrice =
    parseFloat(formData.purchasePrice.replace(/[^0-9.-]+/g, "")) || 0;

  const handleInputChange = (eventOrName, value) => {
    if (typeof eventOrName !== "string") {
      return;
    }

    let updatedProps = {
      [eventOrName]: value,
      balloonType:
        eventOrName === "balloonPaymentPercentage"
          ? FieldCalculationType.PERCENTAGE
          : FieldCalculationType.FIXED,
    };

    onFormDataChange(updatedProps);
  };

  useEffect(() => {
    if (formData.balloonType === FieldCalculationType.PERCENTAGE) {
      const balloonPaymentPercentage = formData.balloonPaymentPercentage || 0;
      onFormDataChange({
        balloonPayment: (
          (balloonPaymentPercentage / 100) *
          purchasePrice
        ).toFixed(2),
      });
    }
  }, [
    onFormDataChange,
    purchasePrice,
    formData.balloonType,
    formData.balloonPaymentPercentage,
  ]);

  useEffect(() => {
    if (formData.balloonType === FieldCalculationType.FIXED) {
      const balloonPayment = formData.balloonPayment || 0;
      onFormDataChange({
        balloonPaymentPercentage: (
          (balloonPayment / purchasePrice) *
          100
        ).toFixed(2),
      });
    }
  }, [
    onFormDataChange,
    purchasePrice,
    formData.balloonType,
    formData.balloonPayment,
  ]);

  const TermOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
  ];

  const inputFields = [
    {
      name: "loanTerm",
      label: "Loan Term",
      placeholder: "Enter loan amount",
      type: "radio",
      options: TermOptions,
    },
    {
      name: "balloonPayment",
      label: "Balloon Payment",
      type: "text",
      format: "money",
    },
    {
      name: "balloonPaymentPercentage",
      label: "Balloon Payment (%)",
      type: "text",
      format: "percentage",
    },
    {
      name: "paymentFrequency",
      label: "Payment Frequency",
      type: "toggle",
      toggle: {
        options: ["Monthly", "Weekly"],
        initialOption: formData.paymentFrequency || "Monthly", // Set default to 'Monthly'
      },
      onChange: (name, value) =>
        onFormDataChange({ name: name, paymentFrequency: value }),
    },
    {
      name: "paymentTiming",
      label: "Payment Timing",
      type: "toggle",
      toggle: {
        options: ["Advance", "Arrears"],
        initialOption: formData.paymentTiming || "Arrears",
      },
      onChange: (name, value) =>
        onFormDataChange({ name: name, paymentTiming: value }),
    },
  ];

  return (
    <div className="iphone">
      <div className="div">
        <div
          style={{
            width: 26,
            height: 26,
            left: 3,
            top: 3,
            position: "relative",
            background: "white",
          }}
          onClick={onBack}
        >
          <img alt="Back" src={backArrowIcon} />
        </div>

        <div className="navbar">
          <div className="navbar-item">
            <div className="ellipse" />
            <div className="text-wrapper-6">Loan</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-2 solid" />
            <div className="text-wrapper-7">Payment</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-3" />
            <div className="text-wrapper-8">Fees</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-4" />
            <div className="text-wrapper-9">Quote</div>
          </div>
          <div className="line" />
          <div className="line-2" />
        </div>

        <div className="text-wrapper-4">How payments are made!</div>

        <div className="frame">
          {inputFields.map((field, index) => (
            <InputField
              key={index}
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
              format={field.format}
              options={field.options}
              toggle={field.toggle}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
            />
          ))}
        </div>

        <div className="overlap" onClick={onShowFees}>
          <div className="text-wrapper-2">Next →</div>
        </div>

        <div className="group">
          <div className="text-wrapper-5">Powered by</div>
          <img className="image" alt="Powered by logo" src={logo} />
        </div>
      </div>
    </div>
  );
};

export default Payments;

Payments.propTypes = {
  onBack: PropTypes.func.isRequired,
  onShowFees: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
};
