import React, { useState } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "./ToggleSwitch";

const InputField = ({
  name,
  label,
  type,
  options,
  format,
  value,
  checked,
  onChange,
  toggle,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputId = `${label.replace(/\s+/g, "-").toLowerCase()}-input`;

  const formatNumberWithCommas = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChange = (e) => {
    if (type === "checkbox") {
      onChange(name, e.target.checked);
    } else if (type === "radio") {
      onChange(name, e.target.value);
    } else {
      if (["money", "percentage"].includes(format)) {
        onChange(name, e.target.value.replace(/[^0-9.]/g, ""));
      } else {
        onChange(e);
      }
    }
  };

  const handleBlur = (e) => {
    let value = e.target.value;

    setIsFocused(false);
    if (value && ["money", "percentage"].includes(format)) {
      value = parseFloat(value);

      if (format === "percentage") {
        value = Math.min(100, value);
      }

      onChange(name, value.toFixed(2).toString());
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const getPlaceholder = () => {
    if (!isFocused) {
      if (format === "money") return "$";
      if (format === "percentage") return "%";
    }
    return "";
  };

  const getTextValue = () => {
    let formattedValue = value;

    if (!isFocused && value) {
      formattedValue = formatNumberWithCommas(formattedValue);

      if (format === "money") formattedValue = "$" + formattedValue;
      if (format === "percentage") formattedValue = formattedValue + "%";
    }

    return formattedValue;
  };

  const renderRadioButtons = () => {
    return (
      <div className="radio-group">
        {options.map((option, index) => (
          <label key={index} className="radio-label">
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={handleChange}
              className="radio-input"
            />
            <span className="radio-custom">{option.label}</span>
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className="frame-2">
      <label className="text-wrapper" htmlFor={inputId}>
        {label}
      </label>
      {type === "checkbox" ? (
        <label className="checkbox-label" htmlFor={inputId}>
          <input
            id={inputId}
            name={name}
            type="checkbox"
            className="checkbox-input"
            checked={checked}
            onChange={(e) => onChange(name, e.target.checked)}
          />
          <span className="checkbox-custom"></span>
          <span className="checkbox-text">{label}</span>
        </label>
      ) : type === "toggle" && toggle ? (
        <ToggleSwitch
          options={toggle.options}
          initialOption={toggle.initialOption}
          onChange={(value) => onChange(name, value)}
          name={name}
        />
      ) : type === "select" ? (
        <select
          id={inputId}
          name={name}
          className="rectangle-select"
          value={value}
          onChange={handleChange}
        >
          {options &&
            options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      ) : type === "radio" ? (
        renderRadioButtons()
      ) : (
        <input
          id={inputId}
          name={name}
          className={`rectangle ${format ? `format-${format}` : ""}`}
          type="text"
          value={getTextValue()}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={getPlaceholder()}
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array,
  format: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  toggle: PropTypes.object,
};

export default InputField;
