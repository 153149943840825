import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InputField from "./InputField";
import backArrowIcon from "../images/back-arrow-icon.png";
import logo from "../images/logo.svg";
import { FieldCalculationType } from "../../shared/constants";

const Fees = ({ onBack, onShowLoanSummary, formData, onFormDataChange }) => {
  const calculateBaseLoanAmount = (formData) => {
    const purchasePrice =
      parseFloat(formData.purchasePrice.replace(/[^0-9.-]+/g, "")) || 0;
    const payoutAmount =
      parseFloat(formData.payoutAmount.replace(/[^0-9.-]+/g, "")) || 0;
    const tradeIn = parseFloat(formData.tradeIn.replace(/[^0-9.-]+/g, "")) || 0;
    const cashDeposit =
      parseFloat(formData.cashDeposit.replace(/[^0-9.-]+/g, "")) || 0;
    return Math.max(0, purchasePrice + payoutAmount - tradeIn - cashDeposit);
  };
  const calculateNetAmountFinanced = (formData, baseLoanAmount) => {
    let netAmount = baseLoanAmount;
    const loanApplicationFee =
      parseFloat(formData.loanApplicationFee.replace(/[^0-9.-]+/g, "")) || 0;
    const brokerOriginationFee =
      parseFloat(formData.brokerOriginationFee.replace(/[^0-9.-]+/g, "")) || 0;

    if (formData.financeApplicationFee) {
      netAmount += loanApplicationFee * 1.1;
    }

    if (formData.financeOriginationFee) {
      netAmount += brokerOriginationFee * 1.1;
    }
    return netAmount;
  };
  const baseLoanAmount = calculateBaseLoanAmount(formData);
  const netAmountFinanced = calculateNetAmountFinanced(
    formData,
    baseLoanAmount,
  );

  const handleInputChange = (eventOrName, value) => {
    let updatedProps = {
      [eventOrName]: value,
    };

    if (["brokeragePercentage", "brokerage"].includes(eventOrName)) {
      updatedProps.brokerageType =
        eventOrName === "brokeragePercentage"
          ? FieldCalculationType.PERCENTAGE
          : FieldCalculationType.FIXED;
    }
    onFormDataChange((state) => {
      return {
        ...state,
        ...updatedProps,
      };
    });
  };

  useEffect(() => {
    if (formData.brokerageType === FieldCalculationType.PERCENTAGE) {
      onFormDataChange((state) => {
        const brokeragePercentage = state.brokeragePercentage || 0;
        return {
          ...state,
          brokerage: ((brokeragePercentage / 100) * netAmountFinanced).toFixed(
            2,
          ),
        };
      });
    }
  }, [
    onFormDataChange,
    netAmountFinanced,
    formData.brokerageType,
    formData.brokeragePercentage,
  ]);

  useEffect(() => {
    if (formData.brokerageType === FieldCalculationType.FIXED) {
      onFormDataChange((state) => {
        const brokerage = state.brokerage || 0;
        return {
          ...state,
          brokeragePercentage: ((brokerage / netAmountFinanced) * 100).toFixed(
            2,
          ),
        };
      });
    }
  }, [
    onFormDataChange,
    netAmountFinanced,
    formData.brokerageType,
    formData.brokerage,
  ]);

  const inputFields = [
    {
      name: "brokerage",
      label: "Brokerage Fee",
      placeholder: "Enter Brokerage Fee",
      type: "text",
      format: "money",
    },
    {
      name: "brokeragePercentage",
      label: "Brokerage Fee (%)",
      placeholder: "Enter Brokerage Fee Percentage",
      type: "text",
      format: "percentage",
    },
    {
      name: "loanApplicationFee",
      label: "Loan Application Fee",
      placeholder: "Enter Loan Application Fee",
      type: "text",
      format: "money",
    },
    {
      name: "brokerOriginationFee",
      label: "Origination Fee",
      placeholder: "Enter Origination Fee",
      type: "text",
      format: "money",
    },
  ];

  return (
    <div className="iphone">
      <div className="div">
        <div
          style={{
            width: 26,
            height: 26,
            left: 3,
            top: 3,
            position: "relative",
            background: "white",
          }}
          onClick={onBack}
        >
          <img alt="Back" src={backArrowIcon} />
        </div>
        <div className="navbar">
          <div className="navbar-item">
            <div className="ellipse" />
            <div className="text-wrapper-6">Loan</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-2 solid" />
            <div className="text-wrapper-7">Payment</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-3 solid" />
            <div className="text-wrapper-8">Fees</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-4" />
            <div className="text-wrapper-9">Quote</div>
          </div>
          <div className="line" />
          <div className="line-2" />
        </div>

        <div className="text-wrapper-4">Fees to be charged</div>

        <div className="frame">
          {inputFields.map((field, index) => (
            <InputField
              key={index}
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
              format={field.format}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
            />
          ))}
          <div className="text-wrapper">
            Which of these fees would you like to finance?
          </div>

          <InputField
            name="financeApplicationFee"
            label="Lender Application Fee"
            type="checkbox"
            checked={formData.financeApplicationFee}
            onChange={handleInputChange}
          />
          <InputField
            name="financeOriginationFee"
            label="Lender Originatioin Fee"
            type="checkbox"
            checked={formData.financeOriginationFee}
            onChange={handleInputChange}
          />
        </div>

        <div className="overlap" onClick={onShowLoanSummary}>
          <div className="text-wrapper-2">Next →</div>
        </div>

        <div className="group">
          <div className="text-wrapper-5">Powered by</div>
          <img className="image" alt="Powered by logo" src={logo} />
        </div>
      </div>
    </div>
  );
};

Fees.propTypes = {
  onBack: PropTypes.func.isRequired,
  onShowLoanSummary: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
};

export default Fees;
